<template>
  <vue-final-modal class="modal-cancel-deposit">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('cancel-deposit')" />
      </div>
    </div>

    <div class="vfm__body">
      <div
        v-if="modalContent"
        class="modal-cancel-deposit__content"
        v-html="DOMPurify.sanitize(marked.parse(modalContent) as string, { FORBID_TAGS: ['style'] })"
      />

      <div class="modal-cancel-deposit__actions">
        <button-base type="primary" size="md" @click="closeModal('cancel-deposit')">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'primaryButton') }}
        </button-base>

        <button-base type="secondary-1" size="md" @click="closeModal('cancel-deposit')">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'secondaryButton') }}
        </button-base>

        <button-base size="xs" type="outlined" @click="closeAllModals">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'confirm') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { marked } from 'marked';
  import { VueFinalModal } from 'vue-final-modal';
  import DOMPurify from 'isomorphic-dompurify';
  import type { CIModalsContent } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['cancelDeposit']>;
    defaultLocaleData: Maybe<CIModalsContent['cancelDeposit']>;
  }>();

  const { closeModal, closeAllModals } = useModalStore();
  const { getContent } = useProjectMethods();
  const modalContent = getContent(props.currentLocaleData, props.defaultLocaleData, 'content');
</script>

<style src="~/assets/styles/components/modal/cancel-deposit.scss" lang="scss" />
